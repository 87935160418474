import React from "react";
import { window } from "browser-monads";
import { PageProps } from "gatsby";
import {
  TwitterShareButton,
  FacebookShareButton,
  LinkedinShareButton,
} from "react-share";

import { SideNav } from "components/guides";
import { Related } from "components/design-system";
import SEO from "components/SEO";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import * as styles from "./guide.module.scss";

import markdownToHtml from "helpers/utils/markdownLoader";
import { BlogFacebook, BlogLinkedIn, BlogTwitter } from "assets/media/svgs";
import { Guide, Image } from "types";

interface GuideTemplateguide extends PageProps {
  pageContext: {
    guide: Guide;
    relatedGuides: Guide[];
  };
}

export const GuideTemplate: React.FC<GuideTemplateguide> = ({
  pageContext: {
    guide: { attributes: guide, id: guideId },
    relatedGuides,
  },
}) => {
  const categoryPath = `/guides/${guide.category.data.attributes.slug}`;
  const relatedItems = [...relatedGuides]
    .filter((item) => guideId !== item.id)
    .slice(0, 2)
    .map(
      ({
        attributes: {
          title,
          featuredImage: {
            data: {
              attributes: { localFile: image, url, ext },
            },
          },
          slug,
          description,
        },
      }) =>
        Object.assign(
          {
            title,
            image,
            link: `${categoryPath}/${slug}`,
            excerpt: description,
          },
          ext === ".gif" ? { featuredImage: url } : { image }
        )
    );

  return (
    <div className={styles.Guide_container}>
      <SEO
        type="article"
        slug={`${categoryPath}/${guide.slug}`}
        title={guide.title}
        datePublished={guide.date}
        image={guide.featuredImage.data.attributes.url}
        description={guide.description}
      />
      <SideNav
        type="Category"
        title={guide.category.data.attributes.name}
        guides={relatedGuides}
        pathPath={`/guides/${guide.category.data.attributes.slug}`}
      />
      <div className={styles.Guide_main}>
        <GuidePreview {...guide} />
        <div className={styles.Guide_related}>
          <Related title="More posts like this" items={relatedItems} />
        </div>
      </div>
    </div>
  );
};

export const GuidePreview: React.FC<Guide["attributes"]> = (guide) => {
  const postURL = `${window.location.href}`;
  const postTitle = guide.title;

  const {
    attributes: { ext: imageExt, url: imageUrl, localFile: imageLocalfile },
  } = guide.featuredImage.data as Image;

  const featuredImage = getImage(imageLocalfile);

  const dateOptions = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const formattedDate = new Date(guide.date).toLocaleDateString(
    "en-US",
    dateOptions as any
  );

  return (
    <div className={styles.Guide}>
      <article>
        <div className={styles.Guide_header}>
          <div className={styles.Guide_meta}>
            <p className={styles.Guide_header_category}>
              {guide.category.data.attributes.name}
            </p>
            <p className={styles.Guide_header_date}>{formattedDate}</p>
          </div>
          <div>
            <h1 className={styles.Guide_header_title}>{guide.title}</h1>
            <div className={styles.Guide_share}>
              <FacebookShareButton url={postURL} quote={postTitle}>
                <div>
                  <BlogFacebook />
                </div>
              </FacebookShareButton>

              <TwitterShareButton url={postURL} title={postTitle}>
                <div>
                  <BlogTwitter />
                </div>
              </TwitterShareButton>
              <LinkedinShareButton url={postURL} title={postTitle}>
                <div>
                  <BlogLinkedIn />
                </div>
              </LinkedinShareButton>
            </div>
          </div>
        </div>

        {imageExt === ".gif" ? (
          <div className={styles.Guide_featuredImage}>
            <img src={imageUrl} alt={guide.title} />
          </div>
        ) : (
          <GatsbyImage
            className={styles.Guide_featuredImage}
            image={featuredImage!}
            alt={guide.title}
          />
        )}
        <div className={styles.Guide_content}>
          <div
            className={styles.Guide_content_main}
            dangerouslySetInnerHTML={{
              __html: markdownToHtml(guide.content),
            }}
          />
        </div>
      </article>
    </div>
  );
};

export default GuideTemplate;
